<template>
  <div>
    <card-component title="Heatmap of Hatchery Customer">
      <div class="columns mb-0">
        <div class="column is-8">
          <gmap-map
              ref="mapRef"
              :zoom="4.55"
              :center="center"
              map-type-id="roadmap"
              style="width: 100%; height: 500px;"
          >
            <gmap-marker
                v-for="(m, index) in markers"
                :key="index"
                :position="m.location"
                :clickable="true"
                :draggable="false"
                @click="showDetail(m)"
            />
            <gmap-heatmap-layer
                :data="markers"
                :options="{maxIntensity: maxIntensity, dissipating: false, radius: 2}"
            />
          </gmap-map>
        </div>

        <div class="column is-4">
          <div style="height: 500px; overflow-y: auto">
            <b-collapse
                class="card mb-0"
                animation="slide"
                v-for="(item, key) of groupedData"
                :key="key"
                :open="isOpen == key"
                @open="isOpen = key">
              <template #trigger="props">
                <div
                    class="card-header"
                    role="button"
                >
                  <div class="card-header-title">
                    <div class="is-flex is-justify-content-space-between" style="width: 100%;">
                      {{ item.company_name }}
                      <span>{{ item.total }}</span>
                    </div>
                  </div>
                  <a class="card-header-icon">
                    <b-icon
                        :icon="props.open ? 'menu-down' : 'menu-up'">
                    </b-icon>
                  </a>
                </div>
              </template>
              <div class="card-content">
                <div class="content">
                  <div class="is-flex is-justify-content-space-between" v-for="(data, dataKey) in item.data"
                       :key="dataKey">
                    {{ data.customer_name }}
                    <span class="has-text-weight-bold">{{ $numberFormat(data.total) }}</span>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
      </div>

      <div class="map-info" v-if="detail">
        <div class="card">
          <div class="card-content px-3 py-2">
            <div>
              <div class="is-flex is-justify-content-space-between is-align-items-center mb-2">
                <h4 class="has-text-weight-bold mb-0">{{ detail.data.company }}</h4>
                <div class="cursor-pointer" @click="detail = null">
                  <b-icon class="has-text-grey" icon="close"/>
                </div>
              </div>
              <div class="columns is-gapless mb-2">
                <div class="column">Total</div>
                <div class="column">:
                  <span class="has-text-weight-bold cursor-pointer is-underline has-text-primary ml-2"
                        @click="clickToCopy" title="Click to Copy to Clipboard">
                    {{ detail.data.total }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </card-component>
  </div>
</template>

<script>
/* eslint-disable */
import {mapActions, mapState} from "vuex";
import CardComponent from "@/components/CardComponent";

export default {
  name: "HatcheryCustomerMap",
  components: {CardComponent},
  props: {
    from: Number,
    to: Number,
  },
  data: () => {
    return {
      isOpen: null,
      center: {lat: -2.5489, lng: 118.0149},
      markers: [],
      maxIntensity: 120,
      detail: null,
    }
  },
  computed: {
    ...mapState('hatchery', [
      'hatchery_customers',
    ]),

    groupedData() {
      return this.hatchery_customers.data.reduce((a, b) => {
        let find = a.find(e => e.company_name === b.company_name)

        if (!find) {
          a.push({
            company_name: b.company_name,
            company_latitude: b.company_latitude,
            company_longitude: b.company_longitude,
            total: b.total,
            data: [b]
          })
        } else {
          find.data.push(b)
          find.total += b.total
        }

        return a
      }, [])
    },
  },
  async created() {
    let payload = {}
    if (this.from) payload.from = this.from
    if (this.to) payload.to = this.to
    await this.getHatcheryCustomers(payload)
    await this.$gmapApiPromiseLazy();
    this.markers = []

    this.maxIntensity = 0

    for (let i = 0; i < this.hatchery_customers.data.length; i++) {
      let data = this.hatchery_customers.data[i]
      this.markers.push({
        location: new google.maps.LatLng({
          lat: parseFloat(data.company_latitude),
          lng: parseFloat(data.company_longitude)
        }),
        weight: data.total,
        data: data
      })
      if (data.total > this.maxIntensity) this.maxIntensity = data.total
    }
  },
  methods: {
    ...mapActions('hatchery', [
      'getHatcheryCustomers',
    ]),

    showDetail(m) {
      this.center = m.location
      this.detail = m
    },

    clickToCopy(e) {
      const el = document.createElement('textarea');
      el.value = e.target.innerText;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);

      this.$swal({
        icon: 'success',
        title: 'Copied to clipboard',
      })
    },
  }
}
</script>
