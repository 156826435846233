<template>
  <div>
    <title-bar :title-stack="titleStack"/>
    <section class="section is-main-section">

      <card-component>
        <div class="is-flex">
          <b-select v-model="type" @input="getData" class="mr-2">
            <option value="">All</option>
            <option value="month">Monthly</option>
            <option value="year">Yearly</option>
            <!--            <option :value="year" v-for="year in years" :key="year">{{ year }}</option>-->
          </b-select>

          <b-datepicker type="month"
                        placeholder="Select Month"
                        v-model="monthlyDate"
                        locale="id-ID"
                        v-if="type === 'month'"
                        @input="getData">
          </b-datepicker>

          <b-select v-model="yearlyDate" @input="getData" v-if="type === 'year'">
            <option :value="year" v-for="year in years" :key="year">{{ year }}</option>
          </b-select>
        </div>
      </card-component>

      <template v-if="!refresh">
        <hatchery-metrics class="mb-4" :from="from" :to="to"/>
        <hatchery-list-table class="mb-4" :from="from" :to="to"/>
        <hatchery-customer-map :from="from" :to="to"/>
      </template>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleBar from '@/components/TitleBar'
import HatcheryMetrics from "@/components/Hatchery/HatcheryMetrics";
import HatcheryListTable from "@/components/Hatchery/HatcheryListTable";
import HatcheryCustomerMap from "@/components/Hatchery/HatcheryCustomerMap";
import CardComponent from "@/components/CardComponent";

export default {
  name: 'HatcheryOverview',
  components: {
    CardComponent,
    HatcheryCustomerMap,
    HatcheryListTable,
    HatcheryMetrics,
    TitleBar
  },
  data: () => {
    return {
      type: '',
      monthlyDate: new Date(),
      yearlyDate: new Date().getFullYear(),
      from: null,
      to: null,
      refresh: false,
    }
  },
  computed: {
    years() {
      let current = 2020
      let res = []
      let currentYear = new Date().getFullYear()

      do {
        res.push(current)
        current++
      } while (current <= currentYear)

      return res
    },

    titleStack() {
      return ['SgaraHatchery', 'Overview']
    }
  },
  methods: {
    getData() {
      this.from = null
      this.to = null

      if (this.type === 'month') {
        this.from = this.$timestamp(this.$moment(this.monthlyDate).startOf('month'))
        this.to = this.$timestamp(this.$moment(this.monthlyDate).endOf('month'))
      } else if (this.type === 'year') {
        let date = this.$moment().set({year: this.yearlyDate})
        this.from = this.$timestamp(date.startOf('year'))
        this.to = this.$timestamp(date.endOf('year'))
      }

      this.refresh = true

      this.$nextTick(() => {
        this.refresh = false
      })
    },
  }
}
</script>
