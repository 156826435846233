<template>
  <div>
    <card-component>
      <div class="is-flex mb-3 is-justify-content-space-between is-align-items-center">
        <div class="is-flex">
          Total Data: <span class="has-text-primary has-text-weight-bold ml-2">{{ hatchery_data.length }}</span>
        </div>

        <div class="has-text-right">
          <b-button type="is-primary" @click="exportToCsv">Export to CSV</b-button>
        </div>
      </div>
      <b-table
        :loading="hatcheries.loading"
        :paginated="paginated"
        :per-page="perPage"
        :striped="true"
        :hoverable="true"
        default-sort="total"
        default-sort-direction="desc"
        :data='hatchery_data'>

        <b-table-column label="Company Name" field="company" sortable v-slot="props" searchable>
          {{ props.row.company }}
        </b-table-column>
        <b-table-column label="Total" field="total" sortable v-slot="props" searchable>
          {{ props.row.total }}
        </b-table-column>

        <section class="section" slot="empty">
          <div class="content has-text-grey has-text-centered">
            <template v-if="hatcheries.loading">
              <p>
                <b-icon icon="dots-horizontal" size="is-large"/>
              </p>
              <p>Fetching data...</p>
            </template>
            <template v-else>
              <p>
                <b-icon icon="emoticon-sad" size="is-large"/>
              </p>
              <p>Nothing's there&hellip;</p>
            </template>
          </div>
        </section>
      </b-table>
    </card-component>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import CardComponent from "@/components/CardComponent";

export default {
  name: 'HatcheryListTable',
  components: {CardComponent},
  props: {
    from: Number,
    to: Number,
  },
  data() {
    return {
      paginated: true,
      perPage: 10,
      farm_type: 0,
      checkedFarms: [],
      subscriptionModal: false,
      selectedFarm: null,

      farmInfo: null,
      showFarmInfo: false,
      farmInfoLoading: false,

      refresh: false,
    }
  },
  computed: {
    ...mapState('hatchery', [
      'hatcheries',
    ]),

    hatchery_data() {
      let data = this.hatcheries.data

      let now = this.$moment()

      if (this.farm_type === 1) data = data.filter(e => e.expired_at > now.valueOf())
      if (this.farm_type === 2) data = data.filter(e => e.expired_at <= now.valueOf())
      if (this.farm_type === 3) data = data.filter(e => -now.diff(this.$moment(e.expired_at), 'days') <= 7 && -now.diff(this.$moment(e.expired_at), 'days') >= 0)

      return data
    },
  },
  created() {
    let payload = {}
    if (this.from) payload.from = this.from
    if (this.to) payload.to = this.to
    this.getHatcheries(payload)
  },
  methods: {
    ...mapActions('hatchery', [
      'getHatcheries',
    ]),

    clickToCopy(e) {
      const el = document.createElement('textarea');
      el.value = e.target.innerText;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);

      this.$swal({
        icon: 'success',
        title: 'Copied to clipboard',
      })
    },

    async handleOpenDetails(farm) {
      this.showFarmInfo = true
      this.farmInfoLoading = true

      this.farmInfo = farm

      if (farm) {
        farm.user = this.users.data.find(e => e.user_id === farm.user_id)

        farm.info = {
          block_count: 0,
          pond_count: 0,
          user_count: 0,
        }

        new Promise((resolve => {
          this.getFarmInfo(farm.farm_id).then(res => {
            resolve({
              farm_id: farm.farm_id,
              data: res
            })
          })
        })).then(res => {
          this.farmInfo.info = res.data
          this.farmInfoLoading = false
        })
      }

    },

    exportToCsv() {
      let users = this.users.data

      let data = this.hatchery_data.sort((a, b) => a.name.localeCompare(b.name, undefined, {
        sensitivity: 'base',
        numeric: true
      })).map(e => {
        let user = users.find(f => f.user_id === e.user_id)
        return [
          e.name,
          e.city.replace(/,/ig, ' '),
          e.location.replace(';', ','),
          this.$dateFormat(e.created_at, 'YYYY-MM-DD HH:mm'),
          this.$dateFormat(e.expired_at, 'YYYY-MM-DD HH:mm'),
          e.days + ' days',
          user ? user.username : '-',
          user ? user.email : '-',
          user ? user.phone + ';' : '-',
        ]
      })

      let csvContent = "data:text/csv;charset=utf-8,";

      data = [
        ['Name', 'City', 'Latitude', 'Longitude', 'Created At', 'Expired Date', 'Subscription Type', 'User Name', 'Email', 'Phone'],
        ...data
      ]

      data.forEach(function (rowArray) {
        let row = rowArray.join(",");
        csvContent += row + "\r\n";
      });

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "Sgara Farm - " + this.$dateFormat() + '.csv');
      document.body.appendChild(link); // Required for FF

      link.click();
    },
  }
}
</script>

<style lang="scss" scoped>

.farm-info {
  position: fixed;
  left: 40px;
  bottom: 40px;
  z-index: 100;
  width: 600px;
  opacity: .86;
  transition: opacity .15s;

  &:hover {
    opacity: 1;
  }
}

</style>
