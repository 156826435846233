<template>
  <div>
    <!--   Farms   -->

    <card-component
      title="Metrics"
      icon="finance"
      hide-content
    />

    <tiles>
      <card-widget
        class="tile is-child"
        type="is-info"
        icon="fishbowl-outline"
        :number="metrics.total_pl.value"
        label="Count of Total PL"
      >
        <b-loading :is-full-page="false" v-model="metrics.total_pl.loading"></b-loading>
      </card-widget>
    </tiles>
  </div>
</template>

<script>
import CardComponent from "@/components/CardComponent";
import Tiles from "@/components/Tiles";
import CardWidget from "@/components/CardWidget";
import {mapActions, mapState} from "vuex";

export default {
  name: "HatcheryMetrics",
  components: {CardWidget, Tiles, CardComponent},
  props: {
    from: Number,
    to: Number,
  },
  computed: {
    ...mapState('hatchery', [
      'metrics',
    ]),
  },
  created() {
    let payload = {}
    if (this.from) payload.from = this.from
    if (this.to) payload.to = this.to
    this.countPl(payload)
  },
  methods: {
    ...mapActions('hatchery', [
      'countPl',
    ]),
  }
}
</script>

<style scoped>

</style>
